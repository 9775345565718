<template>
  <div class="admin-container">
    <template v-if="data">
      <v-row>
        <v-col cols="12" md="12">
          <v-btn
            tile
            small
            @click="
              $router.push({
                name: 'Complaints',
              })
            "
            >Zpět</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="justify-space-between">
        <v-col cols="12" md="3">
          <h1 class="col-secondary-1">Reklamace</h1>
        </v-col>
        <v-spacer></v-spacer>
        <v-col align-self="center">
          <v-row>
            <v-col
              align-self="center"
              class="pa-0 flex-1 mt-8 mt-md-0 d-flex justify-end flex-wrap flex-md-nowrap"
            >
              <v-btn
                x-small
                height="38"
                color="primary"
                tile
                class="mr-md-2 mobile-width-send-message mb-4 mb-md-0"
                @click="toMessages"
              >
                <div class="d-flex flex-align-center">
                  <img
                    :src="require('@/assets/poslat_zpravu.svg')"
                    class="icon-wrapp mr-2"
                    style="width: 13px; height: 13px"
                  />
                  Poslat zprávu
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="data.name"
            :disabled="
              user.role != 'SUPERUSER' && user.role != 'PROJECT_MANAGER'
            "
            label="Název reklamace *"
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="3">
          <DatePicker
            :disableUser="true"
            label="Datum záznamu *"
            @dateChanged="changeDate($event)"
            :initialDate="data.date_of_record"
        /></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field
            :disabled="
              user.role != 'SUPERUSER' && user.role != 'PROJECT_MANAGER'
            "
            v-model="data.claimant"
            label="Jméno pořizovatele záznamu *"
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="3">
          <v-select
            :items="statuses"
            item-text="name"
            item-value="status"
            v-model="data.status"
            label="Stav reklamace *"
            :disabled="
              user.role != 'SUPERUSER' && user.role != 'PROJECT_MANAGER'
            "
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-textarea
            :disabled="
              user.role != 'SUPERUSER' && user.role != 'PROJECT_MANAGER'
            "
            v-model="data.description"
            label="Poznámka *"
            auto-grow
          ></v-textarea>
        </v-col>
      </v-row>

      <input
        style="display: none"
        ref="documentFile"
        type="file"
        class="input--file"
        multiple
        @input="handleInputFile($event.target.files)"
      />
      <div
        class="d-flex flex-column flex-md-row align-center align-md-start doc-wrapper"
      >
        <div
          v-for="(document, documentIdx) in data.documents"
          :key="document.id"
        >
          <div
            class="document-added mr-md-6 cursor-pointer"
            @click="downloadDoc(document.document_id)"
          >
            <div
              class="document-document-mini"
              :style="correctIcon(document)"
            ></div>
            <div class="document-extension">
              {{ document.extension ? document.extension.toUpperCase() : '' }}
            </div>
            <!-- <div class="document-text">
              Dokument <br />{{ document.mimetype }}
            </div> -->
            <div class="document-close" v-if="document.acl.can_delete">
              <!-- v-if="
                  user.role == 'SUPERUSER' || user.role == 'PROJECT_MANAGER'
                " -->
              <button
                @click.stop="
                  (focusedId = document.document_id),
                    (focusedIdx = documentIdx),
                    (dialog = true)
                "
              >
                <!-- @click="handleDeleteFile(document.document_id, documentIdx)" -->
                <img :src="require('@/assets/zavrit.svg')" alt="" />
              </button>
            </div>
          </div>
          <v-text-field
            style="width: 150px"
            v-model="document.name"
            label="Název souboru *"
            :disabled="!document.acl.can_rename"
          ></v-text-field>
        </div>

        <button class="document-input-add" @click="handleClick('documentFile')">
          <!-- v-if="user.role == 'SUPERUSER' || user.role == 'PROJECT_MANAGER'" -->
          <div class="document-input-add-plus">+</div>
          <div class="document-input-add-text">Vložit dokument</div>
        </button>
      </div>
      <input
        style="display: none"
        ref="photoFile"
        type="file"
        class="input--file"
        capture="camera"
        multiple
        @input="handleInputFile($event.target.files)"
      />
      <div
        class="d-flex flex-column flex-md-row align-center align-md-start doc-wrapper"
      >
        <!-- v-if="user.role == 'SUPERUSER' || user.role == 'PROJECT_MANAGER'" -->
        <button class="document-input-add" @click="handleClick('photoFile')">
          <div class="document-input-add-plus">+</div>
          <div class="document-input-add-text">Pořídit fotografii</div>
        </button>
      </div>
      <v-row>
        <v-col cols="12" md="3"></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"></v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col class="text-right">
          <v-btn
            v-if="['SUPERUSER'].includes(user.role)"
            small
            tile
            color="error"
            class="mr-4"
            @click="deleteDialog = true"
            >Odstranit</v-btn
          >
          <v-btn
            small
            tile
            color="secondary"
            class="mr-4"
            @click="
              $router.push({
                name: 'Complaints',
              })
            "
            >Zrušit</v-btn
          >
          <v-btn small tile color="primary" @click="update">Uložit</v-btn>
          <!-- v-if="user.role == 'SUPERUSER' || user.role == 'PROJECT_MANAGER'" -->
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          :max-width="450"
          persistent
          class="rounded-0"
        >
          <v-card>
            <v-card-title class="headline">Odstranit dokument</v-card-title>
            <v-card-subtitle
              >Opravdu si přejete odstranit dokument ?</v-card-subtitle
            >
            <v-card-text>
              <v-row> </v-row>
              <v-row class="mt-6">
                <v-col class="text-center">
                  <v-btn
                    color="primary"
                    small
                    tile
                    width="100%"
                    @click="handleDeleteFile"
                    >Odstranit</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <v-btn small text @click="dialog = false" width="100%"
                    >Zrušit</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions> </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <v-row justify="center">
      <v-dialog
        v-model="deleteDialog"
        :max-width="450"
        persistent
        class="rounded-0"
      >
        <v-card>
          <v-card-title class="headline">Odstranit reklamaci</v-card-title>
          <v-card-subtitle
            >Opravdu si přejete odstranit reklamaci ?</v-card-subtitle
          >
          <v-card-text>
            <v-row> </v-row>
            <v-row class="mt-6">
              <v-col class="text-center">
                <v-btn
                  color="error"
                  small
                  tile
                  width="100%"
                  @click="deleteComplaint"
                  >Odstranit</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn small text @click="deleteDialog = false" width="100%"
                  >Zrušit</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import bus from '../plugins/bus';
import authHandler from '../api/authHandler';
import permissionDenied from '../api/permissionDenied';
import errorHandler from '../api/errorHandler';
import documentService from '../api/documentService';
import complaintService from '../api/complaintService';
import DatePicker from '../components/DatePicker';
const IMAGES_EXT = ['jpg', 'jpeg', 'svg', 'png'];
const PDF = 'pdf';
const ZIP = 'zip';
const PPT = 'ppt';
const XLS = 'xls';

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      deleteDialog: false,
      dialog: false,
      focusedId: null,
      focusedIdx: null,
      data: null,
      documents: [],
      statuses: [],
    };
  },
  async created() {
    // permissionDenied(this.user, this.$router, "SUPERUSER", "PROJECT_MANAGER");
    bus.$emit('processing');
    await this.getStatuses();
    await this.show();
    bus.$emit('processing', false);
  },
  methods: {
    correctIcon(doc) {
      let icon = 'document.svg';
      if (IMAGES_EXT.includes(doc.extension)) icon = 'jpg.svg';
      if (PDF == doc.extension) icon = 'pdf.svg';
      if (doc.extension.includes(ZIP)) icon = 'zip.svg';
      if (doc.extension.includes(PPT)) icon = 'ppt.svg';
      if (doc.extension.includes(XLS)) icon = 'xls.svg';
      return {
        backgroundImage: `url(${require('@/assets/' + icon)})`,
      };
    },
    async deleteComplaint() {
      try {
        bus.$emit('processing');
        await complaintService.destroy(this.$route.params.id);
        bus.$emit('processing', false);
        this.$router.push({ name: 'Complaints' });
      } catch (error) {
        errorHandler(error);
        console.error(error);
        bus.$emit('processing', false);
      }
    },
    toMessages() {
      if (['END_USER', 'COMPANY_CUSTOMER'].includes(this.user.role)) {
        this.$router.push({
          name: 'MessagesDetail',
          params: { id: this.getSelectedBuilding().project_manager.id },
          query: {
            complaint: this.data.id,
          },
        });
      }
      if (['PROJECT_MANAGER', 'SUPERUSER'].includes(this.user.role)) {
        this.$router.push({
          name: 'MessagesDetail',
          params: { id: this.getSelectedBuilding().investor.id },
          query: {
            complaint: this.data.id,
          },
        });
      }
    },
    getSelectedBuilding() {
      const building = JSON.parse(localStorage.getItem('building')) || null;
      return building;
    },
    changeDate(v) {
      this.data.date_of_record = v;
    },
    async show() {
      try {
        const data = await complaintService.show(this.$route.params.id);
        this.data = data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getStatuses() {
      try {
        const data = await complaintService.statuses();
        this.statuses = data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async handleDeleteFile() {
      try {
        bus.$emit('processing');
        await documentService._delete(this.focusedId);
        this.data.documents.splice(this.focusedIdx, 1);
        this.dialog = false;
        this.focusedId = null;
        this.focusedIdx = null;
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    handleClick(ref) {
      this.$refs[ref].click();
    },
    async downloadDoc(id) {
      try {
        bus.$emit('processing');
        await documentService.show(id);
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
      }
    },
    async handleInputFile(f) {
      try {
        console.debug(f);
        bus.$emit('processing');
        for (let fidx = 0; fidx < f.length; fidx++) {
          const { data } = await documentService.store(f[fidx]);
          this.data.documents.push({
            document_id: data.id,
            name: data.client_name,
            mimetype: data.mimetype,
            mime: data.mime,
            extension: data.extension,
            acl: {
              can_delete: true,
              can_rename: true,
            },
          });
        }

        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: `Podařilo se nahrát dokument`,
          color: 'info',
        });
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    async update() {
      try {
        bus.$emit('processing');
        const response = await complaintService.update(this.$route.params.id, {
          ...this.data,
        });
        console.debug('Response', response);
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: 'Uloženo',
          color: 'success',
        });
        this.$router.push({
          name: 'Complaints',
        });
      } catch (error) {
        console.error(error);
        bus.$emit('processing', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {},
};
</script>

<style></style>
